export const MENU_ITEMS = [
  {
    name: "TOOLS",
    customProps: "",
  },
  {
    name: "Tutorials",
    customProps: "",
  },
  {
    name: "ABOUT",
    customProps: "",
  },
];
