import {Menu, MenuItem} from "@mui/material";

export const NavItemsMenu = ({anchorEl, open, handleClose}: any) => {
  return (
    <div>
      <Menu
        id="feature-menu"
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{"aria-labelledby": "feature-button"}}
        onClose={handleClose} // it'll call `handleClose` function when u click anywhere except button
        anchorOrigin={{vertical: "bottom", horizontal: "right"}} //when menu opens, it'll open from Bottom of Feature button and towards right
        transformOrigin={{vertical: "top", horizontal: "right"}}
      >
        <MenuItem onClick={handleClose}>Blog</MenuItem>
        <MenuItem onClick={handleClose}>Podcast</MenuItem>
      </Menu>
    </div>
  );
};
