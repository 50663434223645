import "./App.css";
import {LoginSignUpPage} from "./containers/login";
import {NavBar} from "./containers/NavBar";

function App() {
  return (
    <div className="App">
      <NavBar />
      <LoginSignUpPage />
    </div>
  );
}

export default App;
