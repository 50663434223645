import {useAppSelector} from "../../app/hooks";
import Login from "./Login";
import SignUp from "./Signup";

export const LoginSignUpPage = () => {
  const {isUserLoggedIn} = useAppSelector((state) => state.user);
  console.log({isUserLoggedIn});

  if (isUserLoggedIn) return <Login />;
  else {
    return <SignUp />;
  }
};
