import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  isUserLoggedIn: false,
  userAccessibilityButton: "LOGIN",
};

const loginSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state) => {
      state.userAccessibilityButton = "LOGOUT";
      state.isUserLoggedIn = true;
    },
    logout: (state) => {
      state.userAccessibilityButton = "LOGIN";
      state.isUserLoggedIn = false;
    },
  },
});

export default loginSlice.reducer;
export const {login, logout} = loginSlice.actions;
