import {CatchingPokemon, KeyboardArrowDown} from "@mui/icons-material";
import {AppBar, Button, IconButton, Stack, Toolbar, Typography} from "@mui/material";
import React, {useState} from "react";
import {NavBar} from "../../utils/types/navbar";
import {NavItemsMenu} from "../Menu";
import {MENU_ITEMS} from "./menuItem";
// import {useNavigate} from "react-router-dom";
// import {useDispatch, useSelector} from "react-redux";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {login, logout} from "../../features/user/userSlice";

export const MUINavBar = ({appName}: NavBar) => {
  const dispatch = useAppDispatch();
  const {userAccessibilityButton} = useAppSelector((state: any) => state.user);
  console.log("userAccessibilityButton-->", userAccessibilityButton);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoginLogoutClick = () => {
    if (userAccessibilityButton === "LOGIN") dispatch(login());
    if (userAccessibilityButton === "LOGOUT") dispatch(logout());
  };

  return (
    /* AppBar props
         position : by default position prop is set to 'fixed'
    */
    <AppBar position="static">
      {/* Toolbar add some padding on the left and right side of the NavBar */}
      <Toolbar>
        <IconButton size="large" edge="start" color="inherit" aria-label="logo">
          <CatchingPokemon />
        </IconButton>
        {/* 
         sx={{ flexGrow: 1 } : "PokemonApp" will take entire available space, so feature/login button will go in right
        */}
        <Typography component="div" variant="h6" sx={{flexGrow: 1}}>
          {appName}
        </Typography>
        <Stack direction="row" spacing={2}>
          {MENU_ITEMS.map((item, index) => (
            <Button
              key={index}
              color="inherit"
              id={`${item.name}-button`}
              onClick={handleClick}
              endIcon={<KeyboardArrowDown />}
              aria-controls={open ? `${item.name}-menu` : undefined} //for accessability (eg text to speech by browser)
              aria-haspopup="true" //for accessability (eg text to speech by browser)
              aria-expanded={open ? "true" : undefined} //for accessability (eg text to speech by browser)
            >
              {item.name}
            </Button>
          ))}
          <Button
            color="inherit"
            id="login-button"
            onClick={handleLoginLogoutClick}
            aria-controls={open ? `login-menu` : undefined} //for accessability (eg text to speech by browser)
            aria-haspopup="true" //for accessability (eg text to speech by browser)
            aria-expanded={open ? "true" : undefined} //for accessability (eg text to speech by browser)
          >
            {userAccessibilityButton}
          </Button>
        </Stack>
        {/* 
          Menu will be hidden by default, to show it we need to make use of "open" prop
        */}
        <NavItemsMenu anchorEl={anchorEl} open={open} handleClose={handleClose} />
      </Toolbar>
    </AppBar>
  );
};
